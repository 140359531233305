import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import AnimationsMethod from '@animations';
import CarrouselMethod from './caroussel';
import SliderMethod from './slider';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotToastService } from '@ngneat/hot-toast';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetailSliderComponent } from './detail-slider/detail-slider.component';

@Component({
  selector: 'app-zalona-home',
  templateUrl: './zalona-home.component.html',
  styleUrls: ['./zalona-home.component.scss'],
})
export class ZalonaHomeComponent implements OnInit {
  public sliderMain: any = SliderMethod;
  public carousselMain: any = CarrouselMethod;
  public animationsMain: any = AnimationsMethod;
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public data: any = [
    {
      img: 'nocturne.jpg',
      alt: 'Nouveau SAUNA chez Zalôna',
      title: 'Zalona',
      subTitle: 'Nocturne',
      description: `
      Zalôna vous accueille chaque 3ème Jeudi du mois de 19h à 22h.<br/>Pour cette occasion, nous vous créerons un soin dînatoire à retrouver dans nos offres du moment, mais vous pouvez aussi créer le votre à partir de notre carte de soins.<br/>Réservation uniquement par téléphone`,
    },
    {
      img: '1z234g5-5268-4270-9442-f292ebf7bfe3_sauna.jpg',
      alt: 'Nouveau SAUNA chez Zalôna',
      title: 'Sauna infra-rouge',
      subTitle: 'Détoxification et relaxation',
      description: `
      Nouveau chez Zalôna<br/>Venez découvrir notre Sauna infra-rouge<br/>Détoxification et relaxation garantie`,
    },
    // {
    //   img: '1z234g5-5268-4270-9442-f292ebf7bfe3_canope.jpg',
    //   alt: 'Nouvelle gamme de cosmétique : La Canopée',
    //   title: 'La Canopee',
    //   subTitle: 'Les explorateurs de la beauté',
    //   description: `
    //   Nouveau chez Zalôna<br/>Cosmétiques 100% d'origine naturelle<br />Soins visage sur Mesure`,
    // },
    {
      img: 'remise-en-forme.jpg',
      alt: 'Salle de sport privée haut de gamme',
      title: 'Zalona',
      subTitle: 'Votre institut a enfin son coach dédié',
      description: `
      Nous sommes fiers de vous annoncer que l'institut <strong>Zalôna</strong> a maintenant son Coach sportif et diététique attitré.<br/>
      Prenez votre vie en main avec Mylas Coach<br /><u><strong>Les mots du Coach</strong></u>:<br /> En tant que Coach fitness qualifié depuis 2010, j'aide mes clients à déployer leur potentiel.<br>Travaillons ensemble à votre remise en forme.<br/><br/><ul><li>Coach sportif depuis 12 ans et diplômé d'état</li><li>Formé et certifié dans divers concepts et en nutrition sportive</li></ul>Le sport est une passion et c'est surtout devenu un équilibre de vie</br> au cours de mes années d'expérience en salle, j'ai pu acquérir toutes les connaissances nécessaires à un bon éducateur sportif, mais aussi une expérience pratique grâce à mon implication personnelle en structure d'accueil.<br/><br/>Les différents objectifs et la diversité des adhérents m'ont permis de mettre à profit les connaissance apprises mais aussi d'innover en matière de coachin et de personnalisation de programme<br/><strong>Mylas Coach</strong>`,
      // btn: 'Voir le détails',
    },
    {
      img: '1de94078-5268-4270-9442-f292ebf7bfe3.jpg',
      alt: "Présentation de l'institut ZALÔNA à Triel sur Seine",
      title: 'Zalona',
      subTitle: 'Bienvenue chez Zalôna',
      description: `
      Entre <strong>salle de remise en forme privée</strong> et <strong>institut de beauté et de bien-être</strong>, l’institut <strong>Zalôna</strong> vous attend à Triel-sur-Seine et vous propose des prestations qui vont de l’épilation au soin spécifique en passant par des séances de <strong>balnéo</strong>.<br /><br />
      Créé par Lydie et Océane, l’institut de beauté et de <strong>fitness</strong> Zalôna a tout pour vous donner envie d’y venir et d’y revenir.<br />Son ambiance <strong>cosy</strong> et <strong>chaleureuse</strong>, sa large palette de services et de prestations vous sont accessibles du mardi au samedi au numéro 39 Sente des Châtelets.<br /><br />
      Si la salle de remise en forme <strong>privée</strong> vous fait de l’œil, incontestablement les nombreux massages <strong>bien-être</strong> ne vous laissent pas indifférent.e. <strong>En solo ou en duo</strong> pour un partage de douceur, vous découvrez les douces senteurs des soins aromatiques durant un massage relaxant ou ré-équilibrant.<br /><br />Lydie et Océane ont pris garde de s’entourer de produits les plus naturels possible et les soins <strong>LPG Endermologie</strong> pour que vous retrouviez un visage et un corps tonique ou simplement… <strong>détoxifiés</strong> !<br /><br />
      N’hésitez plus, mettez le cap sur l’institut de beauté et de bien-être Zalona pour y vivre également une <strong>véritable escapade</strong>. Sans avoir besoin de votre passeport, vous allez voguer jusqu’en bord de mer grâce à la balnéo et au <strong>gommage</strong> mêlant pulpe de noix de coco, poudre de noyaux de litchi et sel de Guérande… déconnexion garantie !`,
      // btn: 'Voir la description',
    },
    {
      img: 'cae9e615-4a93-4f8f-932f-209111654cf02.jpg',
      alt: 'Balnéothérapie chez Zalôna - Institut de beauté à Triel 78',
      title: 'Relaxation',
      subTitle: 'Balnéothérapie',
      description: `Balnéothérapie Duo ou Solo, bain hydromasssant... nous vous proposons des équipements de haute qualité pour des moments privilégiés.<br/>
        Prestations uniquement sur rdv en complément d'un autre soin. Les baignoires Balneo : un véritable remède ! Elles permettent entre autres de soulager les maux de tête et l'asthme, elles assouplissent les tissus suite à la dilatation des pores et favorisent l'élimination de la cellulite. De même, leur rôle majeur consiste à soulager les douleurs dorsales et cervicales..`,
      // btn: 'Voir le détails,
    },
    // {
    //   img: '002.jpg',
    //   title: 'Yonka',
    //   subTitle: 'Votre marque innovante',
    //   description: `Pionniers de l’aromathérapie, les Laboratoires Multaler créent en 1954 les soins phyto-aromatiques Yon-Ka® Paris. 100% Française et familiale, la marque Yon-Ka apporte à la peau ce que la plante a de plus précieux : son huile essentielle vitalisante chargée d’énergie solaire et ses actifs complémentaires (vitamines, acides de fruits, polyphénols, peptides, oligo-éléments...)<br /><br />Une synergie qui répond avec précision, douceur et efficacité aux peaux les plus exigeantes.`,
    //   // btn: 'Voir le détails',
    // },
    {
      img: '05hero.jpg',
      alt: 'Votre coach en nutrition à Triel-sur-Seine',
      title: 'Dietetique',
      subTitle: 'Coach en bien-être et nutrition',
      description: `Votre esthéticienne formée en <b>coaching</b> bien-être et nutrition saura vous accompagner lors de vos <b>bilans minceur</b> avec des programmes sur-mesure pour des <b>résultats adaptés</b>`,
      // btn: 'Voir le détails',
    },
  ];

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    objet: new UntypedFormControl(''),
    message: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    setTimeout(() => {
      this.animationsMain();
      setTimeout(() => {
        this.carousselMain();
      }, 50);
    }, 50);

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      setTimeout(() => {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 1000);
    });
  }

  ngAfterViewInit(): void {
    this.sliderMain();
  }

  ngOnDestroy() {
    this.sliderMain = null;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  adaptImg(size, dt): string {
    if (size >= 1024) {
      return dt.responsive ? dt.responsive.desktop : dt.img;
    } else if (size >= 768 && size < 1024) {
      return dt.responsive ? dt.responsive.laptop : dt.img;
    } else {
      return dt.responsive ? dt.responsive.mobile : dt.img;
    }
  }

  contactFn(text: string): string {
    if (text.length > 300) {
      return text.substring(0, 300) + ' ...';
    }
    return text;
  }

  displayBtn(text: string): boolean {
    if (text.length > 300) {
      return true;
    }
    return false;
  }

  openDetails(index: number) {
    this.dialog.open(DetailSliderComponent, {
      data: {
        details: this.data.find((e, i) => i === index),
      },
      panelClass: 'zalona-modalbox',
      width: '600px',
    });
  }
}
